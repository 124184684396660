<template>
    <div class="topDiv">
        <div class="iconsbtn" :style="lang.lalgin+`:10px`">
            <i class="fas fa-print" @click="einit(), printme(0)" style="color:blue" :title="lang.print_page"></i>
            <i class="fas fa-file-excel" @click="einit(), printme(2)" style="color:green" :title="lang.export_excel"></i>
            <i class="fas fa-file-pdf" @click="einit(), printme(1)" style="color:red" :title="lang.print_pagexport_pdfe"></i>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            sdate: '',
            edate: '',
            exportdata:[],
            exporttitle:[],
            palte_number: '',
            contact_number: '',
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        }
    },
    methods: {
        einit() {
            this.$parent.preparedata();
        },
        printDreport(){
          let pdfFrame = document.body.appendChild(document.createElement('iframe'));
          pdfFrame.style.display = 'none';
          pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
          pdfFrame.src = '../api/exprint.php?sdate='+this.sdate+'&edate='+this.edate;
        },
        printme(id){
            const post = new FormData();
            post.append('type',"startexport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[main][sdate]',this.sdate);
            post.append('data[main][edate]',this.edate);
            post.append('data[main][exporttitle]',this.exporttitle);
            post.append('data[main][palte_number]',this.palte_number);
            post.append('data[main][contact_number]',this.contact_number);

            for(let i=0;i<this.exportdata.length;i++){
                for(let j=0;j<this.exportdata[i].length;j++){
                    post.append('data[data]['+i+'][]',this.exportdata[i][j])
                }
            }
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) =>{
                const res = response.data;
                // console.log(res);
                if(id==0){
                    let pdfFrame = document.body.appendChild(document.createElement('iframe'));
                    pdfFrame.style.display = 'none';
                    pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
                    pdfFrame.src = '../api/exprint.php?expdata='+res.results.data.newID;
                }else if(id==1){
                    window.open('../api/exprint.php?expdata='+res.results.data.newID,"_blank")
                }
                else if(id==2){
                    window.open('../api/exexcel.php?expdata='+res.results.data.newID,"_blank")
                }
            });
        }
    },
}
</script>
<style>
.topDiv{
    position: relative;
}
.iconsbtn{
    position: absolute;
    top:3px;
}
.iconsbtn i{
    margin-inline-end: 5px;
    font-size: 1.5em;
    cursor: pointer;
}
</style>